import React from "react";

// React-Router-Dom imports
import { Switch, Route } from "react-router-dom";

// Pages Import
import HomePage from "./homePage";
import Introduction from "./Introduction"
import DataAndPrep from "./DataAndPrep"
import Resume from "./resume";
import AboutMe from "./aboutMe";
import Results from "./Results";
import Analysis from "./Analysis";
import BetaApp from "./BetaApp";

const Main = () => (
  <Switch>
    <Route exact path="/" component={Introduction} />
    <Route exact path="/Introduction" component={Introduction} />
    <Route exact path="/AboutMe" component={AboutMe} />
    <Route exact path="/DataAndPrep" component={DataAndPrep} />
    <Route exact path="/Results" component={Results} />
    <Route exact path="/Analysis" component={Analysis} />
    <Route exact path="/BetaApp" component={BetaApp} />
  </Switch>
);

export default Main;
